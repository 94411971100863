import Swal from "sweetalert2";
import logo from "./assets/images/logo_78win.png";
import config from "./config";
import girl_pc from "./assets/images/girl-pc_78win.png";
import girl_mb from "./assets/images/girl-mb.png";
import box from "./assets/images/box_78win.png";
import button from "./assets/images/button_78win.svg";

import title from "./assets/images/title_78win.png";
import "./assets/style/reset.css";
import { useCookies } from "react-cookie";

function App() {
  const url_cskh = "https://78win80.com/cskh";
  const [cookies, setCookie] = useCookies(["sended"]);
  const Submit = async () => {
    const name = document.getElementById("name").value;
    const title = document.getElementById("title").value;
    const content = document.getElementById("content").value;
    if (cookies?.sended === 1) {
      return Swal.fire({
        icon: "warning",
        title: "Góp ý bị trùng lặp!",
        html: "<p>Cảm ơn quý khách đã đóng góp ý kiến. </p><p>78WIN kính chúc quý khách có những trải nghiệm tuyệt vời khi tham gia giải trí.</p>",
      });
    }
    if (name !== "" && title !== "" && content !== "") {
      Swal.fire({
        title: "Đang gửi thông tin!",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let raw = JSON.stringify({ name: name, title: title, content: content });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(config.API_URL, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("sent data " + result);
          if (result?.status_code === 200) {
            setCookie("sended", "1", {
              path: "/",
              maxAge: 300,
            });
            Swal.fire({
              icon: "success",
              title: "Gửi thư góp ý thành công!",
              html: "<p>Cảm ơn quý khách đã đóng góp ý kiến. </p><p>78WIN kính chúc quý khách có những trải nghiệm tuyệt vời khi tham gia giải trí.</p>",
              didOpen: () => {
                Swal.hideLoading();
              },
            });
          } else if (result?.status_code === 404)
            Swal.fire({
              icon: "warning",
              title: result?.error,
              footer: `<a href="${url_cskh}">Liên hệ CSKH </a>`,
              didOpen: () => {
                Swal.hideLoading();
              },
            });
          else
            Swal.fire({
              icon: "error",
              title: "Lỗi trong quá trình gửi thư góp ý!",
              footer: `<a href="${url_cskh}">Liên hệ CSKH </a>`,
              didOpen: () => {
                Swal.hideLoading();
              },
            });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Lỗi trong quá trình gửi thư góp ý!",
            footer: `<a href="${url_cskh}">Liên hệ CSKH </a>`,
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        });
    } else {
      Swal.fire({
        icon: "info",
        title: "Hãy điền đầy đủ thông tin!",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };
  return (
    <div className="main">
      <div className="header">
        <img src={logo} alt="" onClick={() => window.location.assign("/")} />
      </div>
      <div className="body">
        <div className="body-text body-bg">
          <div className="body-title">
            <img src={title} alt="" />
          </div>
          <div className="body-box">
            <label for="name" className="label">
              Tên đăng nhập
            </label>
            <div className="box-input">
              <input name="name" id="name" type="text" className="form-control" autoComplete="false" placeholder="Nhập tên đăng nhập" />
            </div>
          </div>
          <div className="body-box">
            <label for="name" className="label">
              Tiêu đề
            </label>
            <div className="box-input">
              <input name="title" id="title" type="text" className="form-control" autoComplete="false" placeholder="Nhập tiêu đề góp ý" />
            </div>
          </div>
          <div className="body-box">
            <label for="name" className="label">
              Nội dung
            </label>
            <div className="box-input">
              <textarea name="content" id="content" type="text" className="textarea" autoComplete="false" placeholder="Nhập nội dung góp ý" />
            </div>
          </div>
          <div className="body-button">
            <img className="button-send" src={button} alt="" onClick={() => Submit()} />
          </div>
        </div>
        <img className="box" src={box} alt="" />
      </div>
      <img className="girl-pc" src={girl_pc} alt="" />
    </div>
  );
}

export default App;
